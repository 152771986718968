import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import ElementUI from "element-ui";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from 'axios'
Vue.prototype.$axios= axios

import news from './api/api'
Vue.prototype.$api = news;

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");