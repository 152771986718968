import axios from './http'
import base from './base'
import QS from 'qs'

const news = {
    // eggDemo() {
    //     return axios.get(`${base.egg}/hqsite/cms/html` );
    // }
    // eggDemo(params) {
    //     return axios.get(`${base.egg}/hqsite/cms/html?page=${params.page}&size=${params.size}&publish_state=1&orderby=create_time` );
    // },
    eggDemo(params) {
        return axios.get(`${base.egg}/zuul/common-hqsite/platform-common-szhqaccess/hqsite/cms/html?page=${params.page}&size=${params.size}&publish_state=1`);
    },
    newsDetail(id) {
        return axios.get(`${base.egg}/zuul/common-hqsite/platform-common-szhqaccess/hqsite/cms/html?id=${id}`);
    },
    cooperation(params) {
        return axios.post(`${base.egg}/zuul/common-hqsite/platform-common-szhqaccess/hqsite/cms/business-cooperation`,params);
    }
}

export default news;